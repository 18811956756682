.Container{
	position: absolute;
	bottom: 20px;
}
.Footer {
	display: flex;
	align-items: center;
	width: 100%;
	height: 60px;
}
.Social {
	list-style: none;
	margin: 0;
	display: flex;
	align-items: center;
	padding: 0 20px 0 0;
}
.Line{
	width: 100%;
	border-bottom: 1px solid #B1B1B1;
}
