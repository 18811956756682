.Header{
	border-bottom: 1px solid #B1B1B1;
	height: 60px;
	display: flex;
	align-items: center;
	/*position: fixed;*/
	/*width: 100%;*/
}
.Menu{
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}
.Menu a {
	cursor: pointer;
	color: #B1B1B1;
	text-decoration: none;
}
.Menu a:hover {
	text-decoration: line-through;
	color: #000;

}

.Menu li+li{
	margin-left: 30px;
}
.Menu li:last-child{
	margin-left: auto;
}